<template>
    <div class="v-modal">
        <loading-spinner v-if="isFormLoaded" class="LoadingSpinner--overlay"/>
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title block-title">Настройки ленты</h3>
                <button
                    type="button"
                    class="btn-block-option close-modal"
                    title="Закрыть окно"
                    aria-label="Close popup"
                    @click="onClose"
                >×</button>
            </div>
            <div class="modal-body">
                <ValidationObserver
                    tag="form"
                    ref="observerQuestion"
                    class="wall-settings"
                    @submit.prevent="onSubmit"
                >
                    <fieldset class="wall-settings__fieldset">
                        <legend class="wall-settings__legend">Настройки показа</legend>
                        <div
                            v-for="(item, key) in displaySettings"
                            :key="`wall-set-display-${key}`"
                            class="wall-settings__item"
                        >
                            <span class="wall-settings__label">
                                {{ item.label }}
                            </span>

                            <label
                                class="material-toggle"
                                :title="`${item.label}: ${item.value ? `Включено` : 'Отключено'}`"
                                :aria-label="item.label"
                            >
                                <input
                                    type="checkbox"
                                    class="material-toggle__input"
                                    v-model="item.value"
                                >
                                <span class="material-toggle__icon"></span>
                            </label>
                        </div>
                    </fieldset>

                    <div class="modal-footer">
                        <button
                            type="submit"
                            class="btn btn-rounded btn-primary-dark"
                        >
                            Сохранить
                        </button>

                        <button
                            type="button"
                            class="v-btn v-btn--transparent"
                            @click="onClose"
                        >
                            Отменить
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import LoadingSpinner from '@/components/LoadingSpinner';

    export default {
        name: 'ModalWallSettings',
        components: {
            LoadingSpinner
        },
        props: {
            afterUpdate: {
                type: Function,
                required: false
            }
        },
        computed: {
            ...mapState('default_data', [
                'wallSettings'
            ])
        },
        data() {
            return {
                isFormLoaded: false,
                displaySettings: {}
            };
        },
        mounted() {
            this.displaySettings = JSON.parse(JSON.stringify(this.wallSettings));
        },
        methods: {
            ...mapMutations('default_data', ['changeWallSettings']),

            async onSubmit() {
                for (const [key, data] of Object.entries(this.displaySettings)) {
                    this.changeWallSettings({ key: key, value: data.value });
                }

                await this.afterUpdate();
                this.$modal.hide('ModalWallSettings');
            },

            onClose() {
                this.$modal.hide('ModalWallSettings');
            }
        }
    };
</script>

<style scoped lang="scss">
    .LoadingSpinner {
        position: fixed;
    }

    .modal {
        &-header {
            padding-bottom: 25px;
        }

        &-title {
            font-size: 24px;
        }

        &-body {
            padding: 0 24px;
        }

        &-footer {
            padding: 16px 0;
            margin-top: 16px;
            justify-content: center;
        }
    }

    .wall-settings {
        $bp: &;

        &__legend {
            font-size: 18px;
            font-weight: 600;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & + #{$bp}__item {
               margin-top: 5px;
           }
        }

        &__label {
            font-size: 14px;
            display: block;
            margin: 0 10px 0 0;
        }
    }
</style>
